import { Icons } from 'components/Icon/constants';
import Icon from 'components/Icon/Icon';
import useOutsideAlerter from 'hooks/useClickOutside';
import { useRef } from 'react';
import { Button } from 'styledComponents/buttons';
import {
	Blanket,
	HeaderTitle,
	ModalContainer,
	ModalContent,
	ModalFooter,
	ModalHeader,
} from './Modal.style';

interface ModalProps {
	title?: string;
	isModalOpen: boolean;
	children: JSX.Element | JSX.Element[];
	useCustomFooter?: boolean;
	btnLabel?: string;
	btnType?: 'submit' | 'button';
	btnId?: string;
	btnAction?: () => void;
	closeModal: () => void;
}

const Modal: React.FC<ModalProps> = ({
	title,
	children,
	btnLabel,
	btnType = 'button',
	useCustomFooter,
	btnId = 'btn-action',
	btnAction,
	closeModal,
}) => {
	const modalRef = useRef(null);
	useOutsideAlerter(modalRef, closeModal);

	return (
		<Blanket>
			<ModalContainer ref={modalRef}>
				<ModalHeader>
					<HeaderTitle>{title}</HeaderTitle>
					<Icon name={Icons.close} onClick={closeModal} />
				</ModalHeader>
				<ModalContent>{children}</ModalContent>
				{!useCustomFooter && (
					<ModalFooter>
						<Button type={btnType} onClick={btnAction} id={btnId}>
							{btnLabel}
						</Button>
					</ModalFooter>
				)}
			</ModalContainer>
		</Blanket>
	);
};

export default Modal;
