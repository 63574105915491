import { createTicket, getEventTypes } from 'api/tickets';
import { Icons } from 'components/Icon/constants';
import Icon from 'components/Icon/Icon';
import { AuthContext } from 'context/AuthContext';
import { eventTypeLabels } from 'models/ticket';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Button } from 'styledComponents/buttons';
import { Input, Select, Textarea } from 'styledComponents/Inputs';
import { Title } from 'styledComponents/Text';
import { toast } from 'react-toastify';
import {
	displayValidationError,
	getValidationError,
} from 'helpers/formHelpers';
import { Form, FormItem, Label } from 'styledComponents/form';
import { Container, Warning } from './SellTicket.style';

export const SellTicket = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset: resetForm,
	} = useForm();
	const { isUserLoggedIn } = useContext(AuthContext);

	const { data: eventTypes } = useQuery('event-types', getEventTypes);

	const createTicketMutation = useMutation(createTicket, {
		onSuccess: () => {
			toast.success('Vaša karta je uspešno objavljena!');
			resetForm();
		},
	});

	const handleCreateTicket = (data: any) => {
		createTicketMutation.mutate({
			e_ticket: false,
			event: data.eventName,
			price: parseInt(data.eventPrice, 10),
			event_type: data.eventType,
			event_location: data.eventLocation,
			start: `${data.eventDate} ${data.eventTime}`,
			end: '',
			description: data.description,
		});
	};

	return (
		<>
			{!isUserLoggedIn && (
				<Warning>
					<Icon name={Icons.warning} /> Morate biti ulogovani kako biste dodali
					kartu
				</Warning>
			)}
			<Container isUserLoggedIn={isUserLoggedIn}>
				<Title mb='50px'>
					Molimo Vas da popunite sva polja kako bismo mogli da okačimo Vašu
					kartu:
				</Title>

				<Icon name={Icons.ticket} />
				<Form
					id='create-ticket-form'
					onSubmit={handleSubmit(handleCreateTicket)}
				>
					<FormItem>
						<Label>Naziv događaja</Label>
						<Input
							placeholder='Naziv događaja'
							{...register('eventName', {
								required: getValidationError('Naziv događaja'),
							})}
							disabled={!isUserLoggedIn}
						/>
						{displayValidationError('eventName', errors)}
					</FormItem>

					<FormItem>
						<Label>Cena</Label>
						<Input
							placeholder='XXXX'
							type='number'
							{...register('eventPrice', {
								required: getValidationError('Cena'),
							})}
							disabled={!isUserLoggedIn}
						/>
						{displayValidationError('eventPrice', errors)}
					</FormItem>

					<FormItem>
						<Label>Mesto održavanja</Label>
						<Input
							placeholder='Mesto održavanja'
							{...register('eventLocation', {
								required: getValidationError('Mesto održavanja'),
							})}
							disabled={!isUserLoggedIn}
						/>
						{displayValidationError('eventLocation', errors)}
					</FormItem>
					<FormItem>
						<Label>Kategorija</Label>
						<Select
							placeholder='Odaberite Kategoriju'
							{...register('eventType', {
								required: getValidationError('Kategorija'),
							})}
							defaultValue='Odaberite kategoriju'
							disabled={!isUserLoggedIn}
						>
							<option disabled>Odaberite kategoriju</option>
							{eventTypes?.map((type: string) => (
								<option key={type} value={type}>
									{eventTypeLabels[type]}
								</option>
							))}
						</Select>
						{displayValidationError('eventType', errors)}
					</FormItem>
					<FormItem>
						<Label>Datum održavanja</Label>
						<Input
							placeholder='Odaberite datum'
							type='date'
							{...register('eventDate', {
								required: getValidationError('Datum održavanja'),
							})}
							disabled={!isUserLoggedIn}
						/>
						{displayValidationError('eventDate', errors)}
					</FormItem>
					<FormItem>
						<Label>Vreme početka</Label>
						<Input
							placeholder='XX:XX'
							type='time'
							{...register('eventTime', {
								required: getValidationError('Vreme početka'),
							})}
							disabled={!isUserLoggedIn}
						/>
						{displayValidationError('eventTime', errors)}
					</FormItem>
					<FormItem>
						<Label>Opis</Label>
						<Textarea
							placeholder='Dodatni opis (broj sedišta, dodatne informacije o preuzimanju...)'
							rows={10}
							{...register('description', {
								maxLength: {
									value: 400,
									message:
										'Polje za opis ne može sadržati više od 400 karaktera',
								},
							})}
							disabled={!isUserLoggedIn}
						/>
						{displayValidationError('description', errors)}
					</FormItem>
				</Form>
				<Button
					form='create-ticket-form'
					type='submit'
					disabled={!isUserLoggedIn || createTicketMutation.isLoading}
				>
					OGLASI KARTU
				</Button>
			</Container>
		</>
	);
};
