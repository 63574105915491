import styled from 'styled-components';

export const Form = styled.form`
	width: 50%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	row-gap: 40px;
	margin-top: 50px;
	margin-bottom: 60px;
	@media only screen and (max-width: 960px) {
		width: 100%;
	}
`;

export const FormItem = styled.div`
	display: flex;
	flex-direction: column;
	gap: 6px;
	width: 48%;
	position: relative;
	@media only screen and (max-width: 960px) {
		width: 100%;
	}
	&:last-child {
		width: 100%;
	}
`;

export const Label = styled.label`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #323232;
`;

export const ErrorMsg = styled.span`
	color: red;
	font-size: 12px;
	position: absolute;
	bottom: -20px;
`;
