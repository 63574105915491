import { ReactComponent as locationIcon } from 'assets/icons/location.svg';
import { ReactComponent as folderIcon } from 'assets/icons/folder.svg';
import { ReactComponent as calendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as checkIcon } from 'assets/icons/check.svg';
import { ReactComponent as cartIcon } from 'assets/icons/cart.svg';
import { ReactComponent as phoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as closeIcon } from 'assets/icons/close.svg';
import { ReactComponent as ticketIcon } from 'assets/icons/ticket.svg';
import { ReactComponent as searchIcon } from 'assets/icons/search.svg';
import { ReactComponent as warningIcon } from 'flat-color-icons/svg/high_priority.svg';
import { ReactComponent as contactIcon } from 'flat-color-icons/svg/contacts.svg';
import { ReactComponent as backIcon } from 'flat-color-icons/svg/previous.svg';
import { ReactComponent as locationOrangeIcon } from 'assets/icons/location_icon.svg';
import { ReactComponent as clockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as rowsIcon } from 'assets/icons/row.svg';
import { ReactComponent as gridIcon } from 'assets/icons/column.svg';
import { ReactComponent as copyIcon } from 'assets/icons/copy.svg';

export enum Icons {
	location = 'location',
	folder = 'folder',
	calendar = 'calendar',
	check = 'check',
	cart = 'cart',
	phone = 'phone',
	close = 'close',
	ticket = 'ticket',
	search = 'search',
	warning = 'warning',
	contact = 'contact',
	back = 'back',
	locationOrange = 'location-orange',
	clock = 'clock',
	rows = 'rows',
	grid = 'grid',
	copy = 'copy',
}

export const icons = {
	[Icons.location]: locationIcon,
	[Icons.folder]: folderIcon,
	[Icons.calendar]: calendarIcon,
	[Icons.check]: checkIcon,
	[Icons.cart]: cartIcon,
	[Icons.phone]: phoneIcon,
	[Icons.close]: closeIcon,
	[Icons.ticket]: ticketIcon,
	[Icons.search]: searchIcon,
	[Icons.warning]: warningIcon,
	[Icons.contact]: contactIcon,
	[Icons.back]: backIcon,
	[Icons.locationOrange]: locationOrangeIcon,
	[Icons.clock]: clockIcon,
	[Icons.rows]: rowsIcon,
	[Icons.grid]: gridIcon,
	[Icons.copy]: copyIcon,
};
