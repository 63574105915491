import { EventType } from 'models/ticket';
import sports from 'assets/images/sports.webp';
import theatre from 'assets/images/theathre.webp';
import conference from 'assets/images/conference.webp';
import recreation from 'assets/images/ostalo.webp';
import music from 'assets/images/music.webp';

export const eventIcons: { [key: string]: any } = {
	[EventType.music]: music,
	[EventType.sport]: sports,
	[EventType.theatre]: theatre,
	[EventType.conference]: conference,
	[EventType.recreation]: recreation,
};

export const getDayMonthYear = (date: string) => {
	const splittedDate = date?.split(' ');
	if (splittedDate) {
		return {
			day: splittedDate[0],
			monthYear: `${splittedDate[1]}`,
		};
	}

	return {
		day: '',
		monthYear: '',
	};
};
