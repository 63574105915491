import styled from 'styled-components';

export const LoginContainer = styled.form`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 22px;
	border-radius: 4px;
	input,
	button {
		width: fill-available;
	}
`;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: inherit;
`;

export const Label = styled.label`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #323232;
	margin-bottom: 6px;
`;
