import { Home } from 'pages/Home/Home';
import Profile from 'pages/Profile/Profile';
import Registration from 'pages/Registration/Registration';
import { SellTicket } from 'pages/SellTicket/SellTicket';
import TermsOfService from 'pages/TermsOfService/TermsOfService';
import { Ticket } from 'pages/Ticket/Ticket';
import { Tickets } from 'pages/Tickets/Tickets';

export type RouteType = {
	path: string;
	element: JSX.Element;
};

export const routes: RouteType[] = [
	{
		path: '/',
		element: <Home />,
	},
	{
		path: '/karte/:slug',
		element: <Ticket />,
	},
	{
		path: '/karte',
		element: <Tickets />,
	},

	{
		path: '/prodaj-kartu',
		element: <SellTicket />,
	},
	{
		path: '/registracija',
		element: <Registration />,
	},
	{
		path: '/moj-nalog',
		element: <Profile />,
	},
	{
		path: '/politika-privatnosti',
		element: <TermsOfService />,
	},
];
