import styled from 'styled-components';
import { ButtonOutline } from 'styledComponents/buttons';

export const Container = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 30px;
	@media only screen and (max-width: 960px) {
		flex-direction: column;
		justify-content: flex-start;
	}
`;

export const TabsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

interface TabProps {
	isActive: boolean;
}

export const Tab = styled(ButtonOutline)<TabProps>`
	border: 1px solid #f18320;
	color: ${(props) => (props.isActive ? '#fff' : '#f18320')};
	background-color: ${(props) => props.isActive && '#f18320'};

	&:hover {
		color: #fff;
		background-color: #f18320;
	}
`;

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: #f8f8f9;
	min-height: 50vh;
	border: 1px solid #f18320;
	border-radius: 8px;
	flex: 1;
	padding: 20px;
	max-width: 100%;
	@media only screen and (max-width: 960px) {
		overflow-x: scroll;
	}
`;
