import {
	createContext,
	Dispatch,
	SetStateAction,
	useMemo,
	useState,
} from 'react';

export const FiltersContext = createContext<{
	eventTypes: string[];
	setEventTypes: Dispatch<SetStateAction<string[]>>;
}>({
	eventTypes: [],
	setEventTypes: () => null,
});

interface FiltersProviderProps {
	children: JSX.Element | JSX.Element[];
}

const FiltersProvider = ({ children }: FiltersProviderProps) => {
	const [eventTypes, setEventTypes] = useState<string[]>([]);

	const filters = useMemo(
		() => ({ eventTypes, setEventTypes }),
		[eventTypes, setEventTypes]
	);

	return (
		<FiltersContext.Provider value={filters}>
			{children}
		</FiltersContext.Provider>
	);
};

export default FiltersProvider;
