import { LoaderContainer } from './Loader.style';

const Loader: React.FC = () => (
	<LoaderContainer>
		<div />
		<div />
		<div />
		<div />
	</LoaderContainer>
);

export default Loader;
