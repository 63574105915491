import styled from 'styled-components';

export const ContentContainer = styled.div`
	display: flex;
	margin-top: 40px;
	gap: 10px;
	width: 100%;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: -50px;
	@media only screen and (max-width: 960px) {
		flex-direction: column;
	}
`;

export const TicketsWrapper = styled.div`
	margin-left: 360px;
	width: 85%;
	max-height: 62vh;
	padding-top: 40px;
	overflow-y: scroll;
	.title {
		padding-bottom: 10px;
	}
	@media only screen and (max-width: 960px) {
		width: fill-available;
		margin-left: unset;
		padding-left: 50px;
		padding-right: 50px;
		max-height: 100%;
		overflow-y: unset;
	}
`;

export const TicketsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	column-gap: 40px;
	row-gap: 20px;
	/* width: 100%; */
	overflow-y: auto;
	padding-right: 10px;
	@media only screen and (max-width: 960px) {
		column-gap: 40px;
	}
`;

export const LeftSideToolbar = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
	width: 260px;
	border-left: 1px solid #e8e8e8;
	padding-left: 20px;
	background-color: #f2f2f2;
	position: fixed;
	height: 100%;
	padding: 150px 25px;

	.filters {
		display: flex;
		flex-direction: column;
		gap: 5px;
		h2 {
			font-size: 20px;
		}
		input {
			width: 15px;
			height: 15px;
			accent-color: #f2994a;
		}
	}

	@media only screen and (max-width: 960px) {
		width: fill-available;
		position: unset;
		padding: 20px;
		flex-direction: row;
	}
`;

export const RadioItem = styled.div`
	display: flex;
	align-items: center;
	gap: 7px;
`;

export const Label = styled.label`
	display: flex;
	font-size: 16px;
	color: #323232;
`;

export const LabelIcon = styled.img`
	width: 18px;
`;

export const LoadingContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 50px;
	height: inherit;
`;

export const SearchBar = styled.div`
	display: flex;
	width: 40%;
	margin-bottom: 20px;
	position: relative;

	svg {
		position: absolute;
		left: 10px;
		top: 10px;
	}
	input {
		width: 100%;
		padding-left: 30px;
	}

	@media only screen and (max-width: 960px) {
		width: 70%;
	}
`;

export const Toolbox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 90%;
`;

export const TicketViewOrder = styled.div`
	display: flex;
	gap: 10px;
	@media only screen and (max-width: 960px) {
		display: none;
	}
`;

interface ViewIconProps {
	isActive: boolean;
}

export const ViewIcon = styled.div<ViewIconProps>`
	svg {
		color: ${(props) => (props.isActive ? '#F2994A' : '#333333')};
		cursor: pointer;
		:hover {
			color: #f2994a;
		}
	}
`;
