import {
	createContext,
	Dispatch,
	SetStateAction,
	useMemo,
	useState,
} from 'react';

export const AuthContext = createContext<{
	isUserLoggedIn: boolean;
	setIsUserLoggedIn: Dispatch<SetStateAction<boolean>>;
	tokenInfo: { expDate: Date | null; userId: number | null };
	setTokenInfo: Dispatch<
		SetStateAction<{ expDate: Date | null; userId: number | null }>
	>;
}>({
	isUserLoggedIn: false,
	setIsUserLoggedIn: () => null,
	tokenInfo: { expDate: null, userId: null },
	setTokenInfo: () => ({
		expDate: null,
		userId: null,
	}),
});

interface AuthProviderProps {
	children: JSX.Element | JSX.Element[];
}

const AuthProvider = ({ children }: AuthProviderProps) => {
	const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
	const [tokenInfo, setTokenInfo] = useState<{
		expDate: Date | null;
		userId: number | null;
	}>({
		expDate: null,
		userId: null,
	});

	const authInfo = useMemo(
		() => ({ isUserLoggedIn, setIsUserLoggedIn, tokenInfo, setTokenInfo }),
		[isUserLoggedIn, setIsUserLoggedIn, tokenInfo, setTokenInfo]
	);

	return (
		<AuthContext.Provider value={authInfo}>{children}</AuthContext.Provider>
	);
};

export default AuthProvider;
