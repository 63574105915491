import { Link } from 'react-router-dom';
import styled from 'styled-components';

export interface AProps {
	align?: string;
}

export interface HProps {
	mb?: string;
	mt?: string;
}

export enum ParagraphSize {
	sm = 'sm',
	md = 'md',
	lg = 'lg',
}

export const paragraphStyles = {
	[ParagraphSize.sm]: {
		fontSize: '14px',
	},
	[ParagraphSize.md]: {
		fontSize: '16px',
	},
	[ParagraphSize.lg]: {
		fontSize: '18px',
	},
};

export interface PProps {
	size?: ParagraphSize;
	weight?: string;
}

export interface TitleProps {
	color?: string;
	regular?: boolean;
	mb?: string;
	mt?: string;
}

export const Title = styled.h2<TitleProps>`
	color: ${(props) => (props.color ? props.color : '#33333')};
	font-size: 24px;
	line-height: 36px;
	margin-bottom: ${(props) => props.mb || 0};
	margin-top: ${(props) => props.mt || 0};
	padding: 0;
	font-weight: ${(props) => (!props.regular ? 500 : 400)};
	display: flex;
	align-items: center;
	gap: 10px;
	@media only screen and (max-width: 960px) {
		font-size: 18px;
		margin-bottom: ${(props) =>
			props.mb ? parseInt(props.mb.replace('px', ''), 10) / 2 : 0}px;
		text-align: center;
	}
`;

export const Subtitle = styled.h3<HProps>`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	margin: 0;
	margin-bottom: ${(props) => props.mb || 0};
	margin-top: ${(props) => props.mt || 0};
	color: #bdbdbd;
`;

export const P = styled.p<PProps>`
	color: #323232;
	margin: 0;
	padding: 0;
	font-size: ${(props) =>
		props?.size
			? paragraphStyles[props?.size].fontSize
			: paragraphStyles[ParagraphSize.md].fontSize};
	font-weight: ${(props) => props.weight || 'normal'};
`;

export const A = styled(Link)<AProps>`
	color: #f18320;
	align-self: ${(props) => props.align || 'auto'};
	text-decoration: none;
	font-size: 18px;
	:hover {
		text-decoration: underline;
	}
`;

export const Currency = styled.span`
	color: #f18320;
`;
