export type LinkType = {
	name: string;
	url: string;
	disabled?: boolean;
};

export const getNavigationLinks = (isUserLoggedIn: boolean): LinkType[] => [
	{
		name: 'Početna',
		url: '/',
	},
	{
		name: 'Karte',
		url: '/karte',
	},
	{
		name: 'Prodaj kartu',
		url: '/prodaj-kartu',
	},
	{
		name: 'Napravi nalog',
		url: '/registracija',
		disabled: isUserLoggedIn,
	},
];
