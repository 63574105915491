import styled from 'styled-components';
import { DebounceInput } from 'react-debounce-input';

export const Input = styled.input`
	outline: none;
	border: 1px solid #e8e8e8;
	border-radius: 4px;
	margin: 0;
	padding: 10px 13px;
	font-family: 'poppins';
	::placeholder {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		display: flex;
		align-items: center;
		color: #9999a0;
	}
`;

export const DebounceStyledInput = styled(DebounceInput)`
	outline: none;
	border: 1px solid #e8e8e8;
	border-radius: 4px;
	margin: 0;
	padding: 10px 13px;
	::placeholder {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		display: flex;
		align-items: center;
		color: #9999a0;
	}
`;

export const Textarea = styled.textarea`
	outline: none;
	border: 1px solid #e8e8e8;
	border-radius: 4px;
	margin: 0;
	padding: 10px 13px;
	font-family: 'poppins';
	::placeholder {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		display: flex;
		align-items: center;
		color: #9999a0;
	}
`;

export const Select = styled.select`
	outline: none;
	border: 1px solid #e8e8e8;
	border-radius: 4px;
	margin: 0;
	padding: 10px;
	appearance: none;
	max-width: 100%;
	text-overflow: ellipsis;
	width: fill-available;
`;
