import styled from 'styled-components';
import { BannerBackgroundImage } from './constants';

export const Wrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: #fffdfa;
	position: relative;
`;

export const LayoutContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: inherit;
	max-width: inherit;
`;

interface PageContainerProps {
	fullWidth: boolean;
}

export const PageContainer = styled.div<PageContainerProps>`
	display: flex;
	flex-direction: column;
	padding-top: 50px;
	${(props) =>
		!props.fullWidth &&
		`padding-left: 102px;
		padding-right: 102px;`}

	@media only screen and (max-width: 960px) {
		${(props) =>
			!props.fullWidth &&
			`padding-left: 50px;
		padding-right: 50px;`}
	}
`;

interface AdContainerProps {
	page: string;
}

export const AdContainer = styled.div<AdContainerProps>`
	width: 100%;
	margin-top: -81px;
	z-index: 0;
	display: flex;
	flex-direction: ${(props) =>
		props.page === '/prodaj-kartu' ? 'column-reverse' : 'column'};
	align-items: center;
	justify-content: center;
	background: ${(props) => `linear-gradient(
			0deg,
			rgba(53, 53, 53, 0.1),
			rgba(53, 53, 53, 0.1)
		),
		url(${
			BannerBackgroundImage(props.page.split('/')[2])[props.page]?.img ||
			BannerBackgroundImage(props.page.split('/')[2])['/karte'].img
		}) no-repeat`};
	background-size: cover;
	background-position: center;
	height: ${(props) => (props.page === '/' ? '400px' : '300px')};
`;

export const TestemonialContainer = styled.div`
	display: flex;
	justify-content: space-around;
	background-color: #f2f2f2;
	@media only screen and (max-width: 960px) {
		flex-direction: column;
	}
`;

export const BigTitle = styled.h2`
	font-style: normal;
	font-weight: 500;
	font-size: 52px;
	line-height: 78px;
	text-align: center;
	color: #ffffff;
	margin: 0;
	@media only screen and (max-width: 960px) {
		font-size: 26px;
	}
`;

export const Footer = styled.footer`
	display: flex;
	justify-content: center;
	padding: 10px;
	background-color: #f2f2f2;
	margin-top: 60px;
	gap: 5px;
	a {
		font-size: 14px;
	}
`;
