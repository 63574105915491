import styled from 'styled-components';

export const Button = styled.button`
	background: #f18320;
	border-radius: 6px;
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	padding: 12px 26px;
	color: #fff;
	display: flex;
	align-items: center;
	gap: 6px;
	border: none;
	cursor: pointer;
	display: flex;
	justify-content: center;

	:hover {
		transition: 0.1s;
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		color: #ffe;
	}

	&:disabled {
		opacity: 0.8;
		cursor: not-allowed;
	}
`;

export const ButtonOutline = styled.button`
	border: 2px solid #ffffff;
	border-radius: 8px;
	background-color: transparent;
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	text-transform: uppercase;
	color: #ffffff;
	padding: 11px 48px;
	cursor: pointer;
	:hover {
		transition: 0.2s;
		border-color: #f18320;
		color: #f18320;
	}
`;
