import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const EventTypeContainer = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 20%;
	text-decoration: none;
	min-height: 220px;

	:hover h2 {
		background: rgba(241, 131, 32, 0.6);
		border: 2px solid #f18220;
	}

	h2 {
		z-index: 0;
		color: #fff;
		font-weight: 500;
		font-size: 18px;
		line-height: 27px;
		padding: 11px 20px;
		background: rgba(24, 24, 24, 0.6);
		border: 2px solid #ffffff;
		border-radius: 8px;
	}

	@media only screen and (max-width: 960px) {
		width: 100%;
		height: 220px;
	}
`;

export const EventPoster = styled.img`
	position: absolute;
	max-width: 100%;
	max-height: 100%;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.08) 0px 8px 11px -3px;

	:hover {
		box-shadow: rgba(0, 0, 0, 0.1) 0px 24px 33px -9px;
	}
`;
