import { Container } from './TermsOfService.style';

const TermsOfService: React.FC = () => (
	<Container>
		<h1>Polisa privatnosti</h1>
		<h2>Prikupljanje informacija</h2>
		<p>
			Prikupljamo sledeće lične informacije od korisnika: broj telefona, ime,
			prezime, e-mail adresu i adresu stanovanja. Ove informacije se prikupljaju
			isključivo u cilju prikazivanja oglasa naših korisnika na kartama kako bi
			drugi korisnici mogli da ih kontaktiraju radi prodaje.
		</p>

		<h2>Korišćenje Google Sign Up-a</h2>
		<p>
			Koristimo Google Sign Up kao mehanizam za prijavljivanje korisnika. Ove
			informacije se takođe koriste isključivo u svrhu prikazivanja oglasa na
			kartama i omogućavanja korisnicima da se kontaktiraju radi prodaje.
		</p>

		<h2>Ograničena upotreba informacija</h2>
		<p>
			Sve informacije koje prikupljamo neće se koristiti ni u jedne druge svrhe
			niti će biti zloupotrebljene. Vaši lični podaci neće biti dostupni trećim
			stranama osim u slučajevima kada je to neophodno radi pružanja usluga ili
			u skladu sa zakonskim zahtevima.
		</p>

		<h2>Zaštita podataka</h2>
		<p>
			Vaše informacije će se čuvati u skladu sa zakonima o zaštiti podataka i
			biće smeštene u sigurnoj bazi podataka na našem serveru. Preduzećemo sve
			razumne mere kako bismo zaštitili vaše podatke od gubitka, zloupotrebe ili
			neovlašćenog pristupa.
		</p>

		<h1>Uslovi korišćenja</h1>
		<h2>Prihvatanje uslova</h2>
		<p>
			Korišćenjem našeg vebsajta, pristajete na sve uslove i odredbe navedene u
			ovoj Polisi privatnosti i Uslovima korišćenja. Ukoliko ne prihvatate ove
			uslove, molimo Vas da ne pristupate ili koristite naš vebsajt.
		</p>

		<h2>Intelektualna svojina</h2>
		<p>
			Svi sadržaji na vebsajtu &quot;kartviska.rs&quot;, uključujući tekst,
			grafiku, logotipe, ikone, slike, audio snimke i softver, su intelektualna
			svojina vlasnika vebsajta ili su korišćeni uz dozvolu vlasnika. Nijedan
			deo sadržaja ne sme biti reprodukovan, distribuiran, modifikovan, prenet
			ili korišćen bez prethodne pismene saglasnosti vlasnika intelektualne
			svojine.
		</p>

		<h2>Odgovornost korisnika</h2>
		<p>
			Korisnici su odgovorni za tačnost i istinitost informacija koje
			dostavljaju prilikom registracije ili korišćenja vebsajta. Korisnici se
			obavezuju da neće dostavljati lažne, obmanjujuće ili neovlašćene
			informacije.
		</p>

		<h2>Ograničenja odgovornosti</h2>
		<p>
			Vlasnik vebsajta &quot;kartviska.rs&quot; ne snosi odgovornost za bilo
			kakvu štetu, gubitak ili povredu nastalu usled korišćenja vebsajta ili
			oslanjanja na informacije objavljene na vebsajtu. Korisnici koriste
			vebsajt na sopstvenu odgovornost.
		</p>

		<h2>Izmena ili prekid usluge</h2>
		<p>
			Vlasnik vebsajta zadržava pravo da u bilo kom trenutku izmeni ili prekine
			pružanje usluga vebsajta &quot;kartviska.rs&quot; bez prethodne najave.
			Vlasnik neće biti odgovoran za bilo kakvu štetu ili gubitak koji može
			nastati usled izmena ili prekida usluge.
		</p>

		<h2>Sporovi i nadležnost</h2>
		<p>
			Sve sporove koji proizađu iz korišćenja vebsajta &quot;kartviska.rs&quot;
			pokušaćemo rešiti sporazumno. Ukoliko spor ne može biti rešen sporazumno,
			nadležan će biti sud u skladu sa važećim zakonodavstvom.
		</p>

		<p>
			Molimo Vas da imate u vidu da ova Polisa privatnosti i Uslovi korišćenja
			mogu biti ažurirani s vremena na vreme. Obaveza je korisnika da povremeno
			pregleda promene kako bi bio informisan o njima.
		</p>

		<p>
			Ako imate bilo kakva pitanja ili nedoumice u vezi sa našom Polisom
			privatnosti i Uslovima korišćenja, možete nas kontaktirati putem
			informacija za kontakt navedenih na našem vebsajtu.
		</p>

		<p>
			Datum poslednjeg ažuriranja: <b>19.05.2023</b>
		</p>
	</Container>
);

export default TermsOfService;
