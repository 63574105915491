import styled from 'styled-components';
import { Title } from 'styledComponents/Text';

export const TicketsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 40px;
	row-gap: 40px;
`;

export const Toolbar = styled.div`
	display: flex;
	margin-top: 20px;
	justify-content: flex-start;
	gap: 30px;
	padding-bottom: 10px;
`;

export const NoDataContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 100px 0;
	h2 {
		text-align: center;
	}
`;

export const CategoryContainer = styled.div`
	display: flex;
	width: 100%;
	margin-bottom: 60px;
	gap: 10px;
	@media only screen and (max-width: 960px) {
		flex-direction: column;
		margin-top: 40px;
	}
`;

export const CardsTitle = styled(Title)`
	position: relative;
	svg {
		width: 40px;
		&:first-child {
			position: absolute;
			top: 2px;
			rotate: 10deg;
		}
	}
`;
