import styled from 'styled-components';
import { Title } from 'styledComponents/Text';

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #ffffff;
	border-radius: 10px;
	max-width: 400px;
	width: 280px;
	padding: 26px 63px;
	z-index: 2;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const ModalHeader = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin-bottom: 50px;
	svg {
		position: absolute;
		right: -25px;
		top: -10px;
		cursor: pointer;
	}
`;

export const HeaderTitle = styled(Title)`
	font-size: 20px;
	line-height: 19px;
	color: #333333;
	justify-self: center;
`;

export const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const Blanket = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	background: rgba(30, 30, 30, 0.6);
	z-index: 2;
`;

export const ModalFooter = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	margin-top: 26px;
	button {
		width: 100%;
	}
`;
