import { getEventTypes, getTickets } from 'api/tickets';
import { Icons } from 'components/Icon/constants';
import Icon from 'components/Icon/Icon';
import Loader from 'components/Loader/Loader';
// import { SelectInput } from 'components/SelectInput/SelectInput';
import { Ticket } from 'components/Ticket/Ticket';
import { FiltersContext } from 'context/FiltersContext';
import { eventTypeLabels, TicketType } from 'models/ticket';
import { FC, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { DebounceStyledInput, Input } from 'styledComponents/Inputs';
import { Title } from 'styledComponents/Text';
// import { sortOptions, SORT_BY_LABEL } from './constants';
import { useMediaQuery } from 'react-responsive';
import { CardsTitle } from 'pages/Home/Home.style';
import {
	ContentContainer,
	Label,
	LoadingContainer,
	RadioItem,
	LeftSideToolbar,
	TicketsContainer,
	TicketsWrapper,
	SearchBar,
	Toolbox,
	TicketViewOrder,
	ViewIcon,
} from './Tickets.style';

export const Tickets: FC = (): JSX.Element => {
	const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
	const [isGridView, setIsGridView] = useState(false);

	const { eventTypes, setEventTypes } = useContext(FiltersContext);

	const { data, isLoading } = useQuery(
		['tickets', eventTypes, searchQuery],
		() => getTickets(eventTypes && eventTypes, searchQuery && searchQuery),
		{
			refetchOnMount: true,
		}
	);

	const { data: filters } = useQuery('event-types', getEventTypes);

	const isMobileScreen = useMediaQuery({
		query: '(max-width: 960px)',
	});

	const handleSetFilters: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		let updatedList = [...eventTypes];
		if (e.target.checked) {
			updatedList = [...eventTypes, e.target.value];
		} else {
			updatedList.splice(eventTypes.indexOf(e.target.value), 1);
		}

		setEventTypes(updatedList);
	};

	const handleSetSearchQuery: React.ChangeEventHandler<HTMLInputElement> = (
		e
	) => {
		setSearchQuery(e.target.value);
	};

	const handleSetIsGridView = (isGrid: boolean) => () => {
		setIsGridView(isGrid);
	};

	return (
		<ContentContainer>
			<LeftSideToolbar>
				{/* Uncomment when we add sorting */}
				{/* <div>
					<Label>{SORT_BY_LABEL}</Label>
					<SelectInput>
						{sortOptions.map((option) => (
							<option key={option.text}>{option.text}</option>
						))}
					</SelectInput>
				</div> */}
				<div className='filters'>
					<Title mb='10px'>Filtriraj</Title>
					{filters?.map((filter: string) => (
						<RadioItem key={filter}>
							<Input
								type='checkbox'
								value={filter}
								onChange={handleSetFilters}
								checked={eventTypes?.includes(filter)}
								disabled={isLoading}
							/>
							<Label>{eventTypeLabels[filter]}</Label>
						</RadioItem>
					))}
				</div>
			</LeftSideToolbar>

			<TicketsWrapper>
				<CardsTitle className='title'>
					<Icon name={Icons.ticket} />
					<Icon name={Icons.ticket} /> Dostupne karte
				</CardsTitle>
				<Toolbox>
					<SearchBar>
						<Icon name={Icons.search} />
						<DebounceStyledInput
							type='text'
							placeholder='Pretraga'
							// className='search-input'
							value={searchQuery}
							onChange={handleSetSearchQuery}
							minLength={2}
							debounceTimeout={500}
						/>
					</SearchBar>
					<TicketViewOrder>
						<ViewIcon isActive={isGridView === false}>
							<Icon name={Icons.rows} onClick={handleSetIsGridView(false)} />
						</ViewIcon>
						<ViewIcon isActive={isGridView}>
							<Icon name={Icons.grid} onClick={handleSetIsGridView(true)} />
						</ViewIcon>
					</TicketViewOrder>
				</Toolbox>

				{!isLoading && !!data?.length && (
					<TicketsContainer>
						{data?.map((ticket: TicketType) => (
							<Ticket
								ticket={ticket}
								width='20%'
								key={ticket.id}
								vertical={!isGridView && !isMobileScreen}
							/>
						))}
					</TicketsContainer>
				)}
				{!!isLoading && (
					<LoadingContainer>
						<Loader />
					</LoadingContainer>
				)}

				{!isLoading && !data?.length && (
					<p>
						Trenutno nemamo karte za tražene kriterijume 😕 <br /> Pokušajte
						kasnije u toku dana, karta viška se može pojaviti svakog časa!
					</p>
				)}
			</TicketsWrapper>
		</ContentContainer>
	);
};
