import { Icons, icons } from './constants';

interface IconProps {
	name: Icons;
	onClick?: () => void;
}

const Icon: React.FC<IconProps> = ({ name, onClick = () => {} }) => {
	const IconComponent = icons[name];

	return <IconComponent onClick={onClick} />;
};

export default Icon;
