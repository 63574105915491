import styled from 'styled-components';
import { Title } from 'styledComponents/Text';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 27px 30px;
	svg {
		color: #f18320;
	}
`;

export const CardTitle = styled(Title)`
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
	color: #333333;
	margin-top: 10px;
	text-transform: uppercase;
	@media only screen and (max-width: 960px) {
		font-size: 18px;
	}
`;
