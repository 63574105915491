import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #fff;
	gap: 20px;
	svg {
		width: 50px;
	}

	#create-user-form {
		.location-input {
			width: 48%;
		}
	}
`;

export const TermsOfServiceContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 5px;

	input {
		width: 20px;
	}
`;
