import {
	deleteTicket,
	editTicket,
	getEventTypes,
	getMyTickets,
} from 'api/tickets';
import { Icons } from 'components/Icon/constants';
import Icon from 'components/Icon/Icon';
import Loader from 'components/Loader/Loader';
import { LoadingContainer } from 'components/Loader/Loader.style';
import Modal from 'components/Modal/Modal';
import { format } from 'date-fns';
import {
	getValidationError,
	displayValidationError,
} from 'helpers/formHelpers';
import useAuth from 'hooks/useAuth';
import { eventTypeLabels, TicketType } from 'models/ticket';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Button } from 'styledComponents/buttons';
import { Form, FormItem, Label } from 'styledComponents/form';
import { Input, Select, Textarea } from 'styledComponents/Inputs';
import { Title } from 'styledComponents/Text';
import { tableHeaders } from './constants';
import {
	DeleteTicketIcon,
	EditTicketIcon,
	FormContainer,
	GoBackIcon,
	ModalContent,
	NoDataContainer,
	Table,
	TicketLink,
} from './TicketsTable.style';

const TicketsTable: React.FC = () => {
	const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
	const [deletionId, setDeletionId] = useState<number | undefined>(undefined);
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [activeTicket, setActiveTicket] = useState<TicketType | undefined>(
		undefined
	);

	const { tokenInfo } = useAuth();

	const { data, refetch, isLoading } = useQuery(
		`my-tickets${tokenInfo.userId}`,
		getMyTickets,
		{
			refetchOnMount: true,
		}
	);
	const deleteMutation = useMutation(deleteTicket);
	const { data: eventTypes } = useQuery('event-types', getEventTypes);
	const editMutation = useMutation(editTicket);

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm();

	const handleCloseModal = () => {
		setIsDeleteModalOpened(false);
	};

	const handleOpenDeleteModal = (id: number) => () => {
		setIsDeleteModalOpened(true);
		setDeletionId(id);
	};

	const handleSetEditMode = (mode: boolean, ticket?: TicketType) => () => {
		if (mode && ticket) {
			setActiveTicket(ticket);
		} else {
			setActiveTicket(undefined);
		}
		setIsEditMode(mode);
	};

	const handleDeleteTicket = () => {
		if (deletionId) {
			deleteMutation.mutate(deletionId, {
				onSuccess: () => {
					refetch();
					toast.success('Karta je izbrisana!');
					setDeletionId(undefined);
					handleCloseModal();
				},
			});
		}
	};

	const handleEditTicket = (ticket: any) => {
		if (activeTicket?.id) {
			editMutation.mutate(
				{
					id: activeTicket.id,
					ticket: {
						...ticket,
						start: `${ticket.event_date}T${ticket.event_time}`,
						description: ticket?.description,
					},
				},
				{
					onSuccess: () => {
						toast.success('Promene su uspešno sačuvane!');
						refetch();
						setIsEditMode(false);
					},
				}
			);
		}
	};

	return (
		<>
			{isLoading && (
				<LoadingContainer>
					<Loader />
				</LoadingContainer>
			)}
			{!isLoading && !data?.length && (
				<NoDataContainer>
					<Title color='#908888'>Još uvek niste nemate dodatih karata.</Title>
				</NoDataContainer>
			)}
			{!isLoading && !!data?.length && !isEditMode && (
				<>
					<Title>Moje Karte</Title>

					<Table>
						<thead>
							<tr>
								{tableHeaders.map((header) => (
									<th key={header}>{header}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{data.map((ticket: TicketType) => (
								<tr key={ticket?.event}>
									<td>
										<TicketLink to={`/karte/${ticket?.id}`}>
											{ticket?.event}
										</TicketLink>
									</td>
									<td>
										{ticket?.start &&
											format(new Date(ticket.start), 'dd.mm.yyyy hh:mm')}
									</td>
									<td>{ticket?.price} rsd</td>
									<td>
										<DeleteTicketIcon
											onClick={handleOpenDeleteModal(ticket?.id)}
										>
											❌
										</DeleteTicketIcon>
										<EditTicketIcon onClick={handleSetEditMode(true, ticket)}>
											✏️
										</EditTicketIcon>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</>
			)}
			{isDeleteModalOpened && (
				<Modal
					isModalOpen={isDeleteModalOpened}
					closeModal={handleCloseModal}
					btnAction={handleDeleteTicket}
					btnLabel='Potvrdi'
				>
					<ModalContent>
						<Title>❌</Title>
						<Title>Da li ste sigurni da želite da izbrišete kartu?</Title>
					</ModalContent>
				</Modal>
			)}
			{isEditMode && (
				<FormContainer>
					<GoBackIcon onClick={handleSetEditMode(false)}>
						<Icon name={Icons.back} /> Nazad na pregled
					</GoBackIcon>
					<Form
						id='create-ticket-form'
						onSubmit={handleSubmit(handleEditTicket)}
					>
						<FormItem>
							<Label>Naziv događaja</Label>
							<Input
								placeholder='Naziv događaja'
								{...register('event', {
									required: getValidationError('Naziv događaja'),
								})}
								defaultValue={activeTicket?.event}
							/>
							{displayValidationError('event', errors)}
						</FormItem>

						<FormItem>
							<Label>Cena</Label>
							<Input
								placeholder='XXXX'
								type='number'
								{...register('price', {
									required: getValidationError('Cena'),
								})}
								defaultValue={activeTicket?.price}
							/>
							{displayValidationError('price', errors)}
						</FormItem>

						<FormItem>
							<Label>Mesto održavanja</Label>
							<Input
								placeholder='Mesto održavanja'
								{...register('event_location', {
									required: getValidationError('Mesto održavanja'),
								})}
								defaultValue={activeTicket?.event_location}
							/>
							{displayValidationError('event_location', errors)}
						</FormItem>
						<FormItem>
							<Label>Kategorija</Label>
							<Select
								placeholder='Odaberite Kategoriju'
								{...register('event_type', {
									required: getValidationError('Kategorija'),
								})}
								defaultValue={activeTicket?.event_type}
								// defaultValue='Odaberite kategoriju'
							>
								<option disabled>Odaberite kategoriju</option>
								{eventTypes?.map((type: string) => (
									<option key={type} value={type}>
										{eventTypeLabels[type]}
									</option>
								))}
							</Select>
							{displayValidationError('event_type', errors)}
						</FormItem>
						<FormItem>
							<Label>Datum održavanja</Label>
							<Input
								placeholder='Odaberite datum'
								type='date'
								{...register('event_date', {
									required: getValidationError('Datum održavanja'),
								})}
								defaultValue={activeTicket?.start?.split('T')[0]}
							/>
							{displayValidationError('event_date', errors)}
						</FormItem>
						<FormItem>
							<Label>Vreme početka</Label>
							<Input
								placeholder='XX:XX'
								type='time'
								{...register('event_time', {
									required: getValidationError('Vreme početka'),
								})}
								defaultValue={activeTicket?.start?.split('T')[1]}
							/>
							{displayValidationError('event_time', errors)}
						</FormItem>
						<FormItem>
							<Label>Opis</Label>
							<Textarea
								placeholder='Dodatni opis (broj sedišta, dodatne informacije o preuzimanju...)'
								rows={10}
								{...register('description', {
									maxLength: {
										value: 400,
										message:
											'Polje za opis ne može sadržati više od 400 karaktera',
									},
								})}
								defaultValue={activeTicket?.description}
							/>
							{displayValidationError('description', errors)}
						</FormItem>
					</Form>
					<Button form='create-ticket-form' type='submit'>
						POSTAVI OGLAS
					</Button>
				</FormContainer>
			)}
		</>
	);
};

export default TicketsTable;
