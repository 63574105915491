import { Icons } from 'components/Icon/constants';
import Icon from 'components/Icon/Icon';
import useAuth from 'hooks/useAuth';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, ButtonOutline } from 'styledComponents/buttons';
import { LinkType, getNavigationLinks } from './constants';
import {
	HeaderContainer,
	Logo,
	Navigation,
	StyledLink,
	Hamburger,
	MobileNavigation,
	CloseIcon,
	ProfileLink,
} from './Header.style';

interface HeaderProps {
	openLoginModal: () => void;
}

export const Header: FC<HeaderProps> = ({ openLoginModal }): JSX.Element => {
	const [navbarActive, setNavbarActive] = useState(false);
	const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

	const { pathname } = useLocation();
	const navigate = useNavigate();
	const { isUserLoggedIn, handleLogoutUser } = useAuth();

	const changeNavbarBackground = () => {
		if (window.scrollY >= 81) setNavbarActive(true);
		else setNavbarActive(false);
	};

	useEffect(() => {
		window.addEventListener('scroll', changeNavbarBackground);
	}, []);

	useEffect(() => {
		setIsMobileMenuVisible(false);
		if (pathname === '/registracija' && isUserLoggedIn) {
			navigate('/');
		}
	}, [isUserLoggedIn, navigate, pathname]);

	const showHideMobileNav = () => {
		setIsMobileMenuVisible(!isMobileMenuVisible);
	};

	const loginButton = isUserLoggedIn ? (
		<>
			<ProfileLink to='/moj-nalog'>Moj nalog</ProfileLink>
			<Button onClick={handleLogoutUser} className='logout'>
				Odjavi se
			</Button>
		</>
	) : (
		<ButtonOutline className='login' onClick={openLoginModal}>
			Uloguj se
		</ButtonOutline>
	);

	// Hide registration link when user is logged in
	const navigation = getNavigationLinks(isUserLoggedIn).map(
		(link: LinkType) =>
			!link.disabled && (
				<StyledLink
					to={link.url}
					isActive={link.url === pathname}
					key={link.name}
				>
					{link.name}
				</StyledLink>
			)
	);

	return (
		<HeaderContainer className={navbarActive ? 'active' : ''}>
			<Logo to='/'>
				kartaviška<span>.rs</span>
			</Logo>
			<Navigation>
				{navigation}
				{loginButton}

				{isMobileMenuVisible ? (
					<CloseIcon onClick={showHideMobileNav}>
						<Icon name={Icons.close} />
					</CloseIcon>
				) : (
					<Hamburger onClick={showHideMobileNav}>
						<div />
						<div />
						<div />
					</Hamburger>
				)}
			</Navigation>
			<MobileNavigation isActive={isMobileMenuVisible}>
				{navigation}
				{loginButton}
			</MobileNavigation>
		</HeaderContainer>
	);
};
