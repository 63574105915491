/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useMemo, useState } from 'react';
import { Title } from 'styledComponents/Text';
import { AuthContext } from 'context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
	Container,
	ContentContainer,
	Tab,
	TabsContainer,
} from './Profile.style';
import TicketsTable from './TicketsTable/TicketsTable';
import EditProfile from './EditProfile/EditProfile';

enum Tabs {
	myTickets = 'tab0',
	myInfo = 'tab1',
}

const Profile = () => {
	const [activeTab, setActiveTab] = useState<Tabs>(Tabs.myTickets);

	const { isUserLoggedIn } = useContext(AuthContext);
	const navigate = useNavigate();

	const handleSetActiveTab: React.MouseEventHandler<HTMLButtonElement> = (
		event
	) => {
		const { id } = event.currentTarget;
		setActiveTab(id as Tabs);
	};

	const isMyTicketsTabActive = useMemo(
		() => activeTab === Tabs.myTickets,
		[activeTab]
	);

	useEffect(() => {
		if (isUserLoggedIn === false && !localStorage.getItem('jwt')) {
			navigate('/');
			toast.warning('Molimo Vas ulogujte se kako bi pristupili ovoj stranici.');
		}
	}, [isUserLoggedIn, navigate]);

	return (
		<Container>
			<TabsContainer>
				<Tab
					id={Tabs.myTickets}
					isActive={isMyTicketsTabActive}
					onClick={handleSetActiveTab}
				>
					Moje karte
				</Tab>
				<Tab
					id={Tabs.myInfo}
					isActive={!isMyTicketsTabActive}
					onClick={handleSetActiveTab}
				>
					Moj profil
				</Tab>
			</TabsContainer>
			<ContentContainer>
				{/* Here goes table component with displayed cards */}
				{isMyTicketsTabActive && <TicketsTable />}

				{/* Here goes profile info component */}
				{!isMyTicketsTabActive && (
					<>
						{' '}
						<Title>Moj profil</Title>
						{/* <EditProfile /> */}U pripremi
					</>
				)}
			</ContentContainer>
		</Container>
	);
};

export default Profile;
