import { Icons } from 'components/Icon/constants';
import HomePoster from 'assets/images/poeple-dancing-poster.webp';
import TicketsPoster from 'assets/images/tickets.webp';
import SellTicket from 'assets/images/sell-ticket.webp';

export const testemonials = [
	{
		iconName: Icons.check,
		title: 'ODABERITE DOGAĐAJ i karte',
		subtitle: 'u samo par klikova',
	},
	{
		iconName: Icons.cart,
		title: 'Povežite se sa prodavcima',
		subtitle: 'platite u dogovoru sa njima',
	},
	{
		iconName: Icons.phone,
		title: 'Preuzmite KARTE',
		subtitle: 'na email ili direktno na vašim vratima',
	},
];

export interface BannerType {
	[key: string]: {
		img: any;
		bigTitle: string;
		title?: string;
	};
}

export const BannerBackgroundImage = (slug: any) => {
	// const splittedArray = slug?.split('-');

	// const id = splittedArray?.splice(-1);

	// const slugTitle = slug.replace(`-${id}`, '');

	const banners = {
		'/': {
			img: HomePoster,
			title: 'VAŠE NAJBOLJE REŠENJE ZA',
			bigTitle: 'Kupovinu i prodaju karata',
		},
		'/karte': {
			img: TicketsPoster,
			bigTitle: 'Karte u ponudi',
		},
		'/prodaj-kartu': {
			img: SellTicket,
			bigTitle: 'Prodavanje karata',
			title: 'POPUNITE FORMU I OGLASITE SVOJU KARTU ZA PRODAJU',
		},
		'/registracija': {
			img: HomePoster,
			bigTitle: 'Napravite nalog',
			title: 'OGLASITE SVOJU KARTU ZA PRODAJU',
		},
		// Add custom banner depending on the event type
		[`/karte/${slug}`]: {
			img: TicketsPoster,
			bigTitle: '',
		},
	};

	return banners;
};
