import { googleSignUp, login } from 'api/user';
import { TokenType } from 'models/user';
import { FieldValues, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Button } from 'styledComponents/buttons';
import { Input } from 'styledComponents/Inputs';

import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { GoogleLogin } from '@react-oauth/google';
import { InputContainer, Label, LoginContainer } from './LoginContainer.style';

interface LoginProps {
	onSuccess: () => void;
}

const Login: React.FC<LoginProps> = ({ onSuccess: closeModal }) => {
	const { mutate: loginMutation } = useMutation(login);
	const { register, handleSubmit } = useForm();

	const googleSignUpMutation = useMutation(googleSignUp);

	const handleLogin = (data: FieldValues) => {
		const { email, password } = data;

		if (email && password) {
			loginMutation(
				{ email, password },
				{
					onSuccess: (response: TokenType) => {
						localStorage.setItem('jwt', response.access_token);
						closeModal();
						toast.info('Dobrodošli nazad!', { icon: '👋' });
					},
					onError: (error) => {
						if ((error as AxiosError).response?.status === 403)
							toast.error(
								'Mejl adresa ili lozinka nisu ispravni. Proverite da li ste dobro uneli mejl adresu i lozinku.'
							);
					},
				}
			);
		}
	};

	return (
		<LoginContainer onSubmit={handleSubmit(handleLogin)}>
			<InputContainer>
				<Label> Email: </Label>
				<Input placeholder='Email' {...register('email')} />
			</InputContainer>
			<InputContainer>
				<Label> Šifra: </Label>
				<Input type='password' placeholder='Šifra' {...register('password')} />
			</InputContainer>
			<InputContainer>
				<Button type='submit'>Uloguj se</Button>
			</InputContainer>
			ili
			<GoogleLogin
				onSuccess={(res) => {
					googleSignUpMutation.mutate(res?.credential as string, {
						onSuccess: (data) => {
							localStorage.setItem('jwt', data?.access_token as string);
							closeModal();
							toast.info('Dobrodošli nazad!', { icon: '👋' });
						},
					});
				}}
				width='280px'
				text='continue_with'
				locale='sr'
			/>
		</LoginContainer>
	);
};

export default Login;
