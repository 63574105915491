import { useCallback, useContext, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from 'context/AuthContext';

const useAuth = () => {
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const token = localStorage.getItem('jwt');

	const { isUserLoggedIn, setIsUserLoggedIn, tokenInfo, setTokenInfo } =
		useContext(AuthContext);

	const handleSetIsUserLoggedIn = useCallback(
		(isLogged: boolean) => setIsUserLoggedIn(isLogged),
		[setIsUserLoggedIn]
	);

	const handleLogoutUser = useCallback(() => {
		localStorage.removeItem('jwt');
		handleSetIsUserLoggedIn(false);
		setTokenInfo({
			expDate: null,
			userId: null,
		});
		if (pathname === '/moj-nalog') {
			navigate('/');
		}
	}, [handleSetIsUserLoggedIn, navigate, pathname, setTokenInfo]);

	useEffect(() => {
		if (token) {
			const { exp, user_id } = jwtDecode(token) as {
				exp: number;
				user_id: number;
			};

			setTokenInfo({
				expDate: new Date(exp * 1000),
				userId: user_id,
			});

			const exp1 = new Date(exp * 1000);

			if (exp1 < new Date()) {
				handleLogoutUser();
			} else {
				handleSetIsUserLoggedIn(true);
			}
		}
	}, [
		token,
		pathname,
		handleSetIsUserLoggedIn,
		handleLogoutUser,
		setTokenInfo,
	]);

	return {
		tokenInfo,
		isUserLoggedIn,
		setTokenInfo,
		handleSetIsUserLoggedIn,
		handleLogoutUser,
	};
};

export default useAuth;
