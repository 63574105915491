import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Table = styled.table`
	display: table;
	border-collapse: collapse;
	margin-top: 20px;

	th {
		text-align: left;
		padding: 7px;
	}

	thead {
		background-color: #f18320;
		border-radius: 10px;
		th:first-child {
			border-top-left-radius: 8px;
		}
		th:last-child {
			border-top-right-radius: 8px;
		}
	}

	td {
		padding: 7px 5px;
	}

	tbody {
		tr:nth-child(odd) {
			background-color: #fff;
		}
		td:last-child {
			padding-left: 30px;
		}
	}
`;

export const DeleteTicketIcon = styled.span`
	margin-right: 5px;
	cursor: pointer;
`;

export const EditTicketIcon = styled.span`
	cursor: pointer;
`;

export const TicketLink = styled(Link)`
	color: #0000ee;
	text-decoration: none;
	:hover {
		text-decoration: underline;
	}
`;

export const NoDataContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 150px;
`;

export const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	h2 {
		text-align: center;
	}
`;

export const FormContainer = styled.div`
	form {
		width: 100%;
	}
`;

export const GoBackIcon = styled.div`
	cursor: pointer;
	max-width: 200px;
	svg {
		width: 20px;
		polygon {
			fill: #000dee;
		}
	}
	display: flex;
	margin-top: 20px;
	color: #000dee;
	font-weight: bold;
	:hover {
		text-decoration: underline;
	}
`;
