import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { P, Title } from 'styledComponents/Text';

export interface TicketProps {
	width?: string;
}

export const TicketContainer = styled.div<TicketProps>`
	width: ${(props) => props.width};
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	text-decoration: none;
	@media only screen and (max-width: 960px) {
		width: 100%;
	}
`;

export const TicketPoster = styled(Link)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	position: relative;
`;

export const TicketDate = styled.div`
	position: absolute;
	padding-left: 20px;
	padding-top: 20px;
`;

export const PriceLocationContainer = styled.div`
	position: absolute;
	bottom: 0;
	width: fill-available;
	display: flex;
	justify-content: space-between;
	color: #fff;
	background: rgba(30, 30, 30, 0.8);
	padding: 10px 20px;
	max-width: 100%;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
`;

export const TicketInfo = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const InfoCol = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Day = styled(Title)`
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
	text-align: center;
	color: #ffffff;
	background: rgba(255, 255, 255, 0.2);
	border: 1px solid #ffffff;
	border-radius: 4px;
	padding: 6px 10px;
	max-width: max-content;
`;

export const MonthYear = styled(P)`
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #ffffff;
	text-transform: uppercase;
`;

export const EventName = styled(Link)`
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 27px;
	color: #333333;
	text-decoration: none;
	:hover {
		color: #f18320;
	}
`;

export const EventCategory = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #333333;
	text-decoration: none;
	display: flex;
	align-items: center;
	gap: 5px;
`;

export const EventTime = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Time = styled(P)`
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	text-align: right;
	color: #333333;
`;

export const EventLocation = styled(P)`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	color: #ffffff;
	display: flex;
	align-items: center;
	gap: 5px;
`;

export const Price = styled(P)`
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 21px;
	color: #ffffff;
`;

export const TicketIcon = styled.img`
	max-width: 100%;
	width: 100%;
	border-radius: 8px;
`;

export const VerticalContainer = styled.div`
	display: flex;
	width: 100%;

	.ticket-poster {
		max-width: 300px;
	}
	img {
		height: 100%;
	}
	.event-category {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: #828282;
	}
	.event-name {
		font-weight: 600;
		font-size: 26px;
		line-height: 39px;
		color: #000000;
		:hover {
			color: #f18320;
		}
	}
`;

export const VerticalInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 25px;
	padding: 16px 0;
`;

export const DashedContainer = styled.div`
	border: 1px dashed #bdbdbd;
	display: flex;
	width: 60%;
	justify-content: space-between;
	padding-left: 55px;
	padding-right: 73px;
	margin-left: -10px;
	background-color: #fff;
`;

export const InfoSection = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Label = styled.h3`
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 27px;
	color: #333333;
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 8px;
	margin-top: 0;
`;

export const VerticalPrice = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	h2 {
		font-weight: 600;
		font-size: 26px;
		line-height: 39px;
	}
`;
