import styled from 'styled-components';
import { Title } from 'styledComponents/Text';

interface ContainerProps {
	isUserLoggedIn: boolean;
}

export const Container = styled.div<ContainerProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #fff;
	gap: 20px;
	opacity: ${(props) => (props.isUserLoggedIn ? 1 : 0.7)};
`;

export const Warning = styled(Title)`
	padding: 5px 20px;
	background-color: #ffc10770;
	border: 1px solid #ffc107;
	border-radius: 4px;
	color: #323232;
	font-size: 16px;
	margin: 0 auto;
	width: max-content;
	margin-bottom: 20px;
	max-width: fill-available;

	svg {
		width: 25px;
		path:first-child {
			fill: #ffc107;
		}
	}
`;
