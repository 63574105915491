import Icon from 'components/Icon/Icon';
import {
	getValidationError,
	displayValidationError,
} from 'helpers/formHelpers';
import { FieldValues, useForm } from 'react-hook-form';
import { Icons } from 'components/Icon/constants';
import { Button } from 'styledComponents/buttons';
import { Form, FormItem, Label } from 'styledComponents/form';
import { Input } from 'styledComponents/Inputs';
import { P, ParagraphSize, Title } from 'styledComponents/Text';
import { CreateUserRequestType } from 'models/user';
import { useMutation } from 'react-query';
import { register as createUser, googleSignUp } from 'api/user';
import { toast } from 'react-toastify';
import { LoadingContainer } from 'components/Loader/Loader.style';
import Loader from 'components/Loader/Loader';
import { GoogleLogin } from '@react-oauth/google';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Container, TermsOfServiceContainer } from './Registration.style';

const Registration: React.FC = () => {
	const [isTermsAccepted, setIsTermsAccepted] = useState(false);

	const handleSetTermsAccepted = () => {
		setIsTermsAccepted(!isTermsAccepted);
	};

	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm();
	const navigate = useNavigate();

	const registerMutation = useMutation(createUser);

	const googleSignUpMutation = useMutation(googleSignUp);

	const handleCreateUser = (user: CreateUserRequestType | FieldValues) => {
		if (!isTermsAccepted) {
			toast.error(
				'Molimo Vas prihvatite uslove korišćenja kako bi mogli da registrujemo Vaš nalog',
				{
					autoClose: 10000,
					position: 'top-center',
				}
			);
		} else {
			registerMutation.mutate(
				{ ...(user as CreateUserRequestType) },
				{
					onSuccess: () => {
						toast.success(
							'Molimo Vas proverite Vaš mejl kako biste potvrdili registraciju. Ukoliko ne vidite poruku o registraciji, proverite i spam poštu.',
							{
								autoClose: false,
								position: 'top-center',
							}
						);
						reset();
					},
					onError: () => {
						toast.error(
							'Došlo je do greške prilikom registracije. Da li već imate nalog na ovoj mejl adresi?',
							{
								autoClose: 10000,
								position: 'top-center',
							}
						);
					},
				}
			);
		}
	};

	return (
		<Container>
			<Title mb='50px'>
				Popunite formu i otvorite nalog kako biste prodali karte:
			</Title>
			<Icon name={Icons.contact} />
			{/* TERMS OF USE CHECKBOX ABOVE EVERYTHING; NEEDS TO BE CHECKED FOR REGISTER or GOOGLE SIGN UP */}
			{registerMutation.isLoading && (
				<LoadingContainer>
					<Loader />
				</LoadingContainer>
			)}
			{!registerMutation.isLoading && (
				<>
					<Form id='create-user-form' onSubmit={handleSubmit(handleCreateUser)}>
						<FormItem>
							<Label>Ime</Label>
							<Input
								placeholder='Ime'
								{...register('first_name', {
									required: getValidationError('Ime'),
								})}
							/>
							{displayValidationError('first_name', errors)}
						</FormItem>

						<FormItem>
							<Label>Prezime</Label>
							<Input
								placeholder='Prezime'
								{...register('last_name', {
									required: getValidationError('Prezime'),
								})}
							/>
							{displayValidationError('last_name', errors)}
						</FormItem>

						<FormItem>
							<Label>Mejl adresa</Label>
							<Input
								placeholder='moj@mejl.com'
								type='email'
								{...register('email', {
									required: getValidationError('Mejl adresa'),
								})}
							/>
							{displayValidationError('email', errors)}
						</FormItem>
						<FormItem>
							<Label>Lozinka</Label>
							<Input
								placeholder='Lozinka'
								type='password'
								{...register('password', {
									required: getValidationError('Lozinka'),
								})}
							/>
							{displayValidationError('password', errors)}
						</FormItem>
						<FormItem>
							<Label>Kontakt telefon</Label>
							<Input
								placeholder='+381 65 1234567'
								{...register('phone_number', {
									required: getValidationError('Kontakt telefon'),
								})}
							/>
							{displayValidationError('phone_number', errors)}
						</FormItem>
						<FormItem className='location-input'>
							<Label>Mesto</Label>
							<Input
								placeholder='Beograd'
								{...register('location', {
									required: getValidationError('Mesto'),
								})}
							/>
							{displayValidationError('location', errors)}
						</FormItem>
					</Form>

					<FormItem>
						<TermsOfServiceContainer>
							<Label as='label' htmlFor='privacy-policy'>
								Saglasan sam sa{' '}
							</Label>
							<Link to='/politika-privatnosti' target='_blank'>
								polisom privatnosti i uslovima koriscenja
							</Link>{' '}
							<Input
								type='checkbox'
								id='privacy-policy'
								onChange={handleSetTermsAccepted}
								checked={isTermsAccepted}
							/>
						</TermsOfServiceContainer>
					</FormItem>

					<Button form='create-user-form' type='submit'>
						Napravi nalog
					</Button>
				</>
			)}
			ili
			<GoogleLogin
				onSuccess={(res) => {
					googleSignUpMutation.mutate(res?.credential as string, {
						onSuccess: (data) => {
							localStorage.setItem('jwt', data?.access_token as string);
							toast.info('Dobrodošli!', { icon: '👋' });
							navigate('/');
						},
					});
				}}
				locale='sr'
				text='continue_with'
				size='large'
			/>
			<P size={ParagraphSize.sm}>
				Klikom na dugme (google registracija) oznacavate da ste upoznati sa
				nasom{' '}
				<Link to='/politika-privatnosti' target='_blank'>
					polisom privatnosti i uslovima koriscenja
				</Link>{' '}
				i prihvatate sve navedene uslove
			</P>
		</Container>
	);
};

export default Registration;
