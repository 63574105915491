import styled from 'styled-components';
import { P } from 'styledComponents/Text';

export const ContentContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 50px;
	background-color: #fff;
	@media only screen and (max-width: 960px) {
		flex-direction: column;
		justify-content: flex-start;
	}
`;

export const UserContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	/* background: #f183204d; */
	border-radius: 4px;
	padding: 10px;
	height: fit-content;
	@media only screen and (max-width: 960px) {
		width: 100%;
	}
`;

export const TicketContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 40%;
	@media only screen and (max-width: 960px) {
		width: 100%;
	}
`;

export const Price = styled.div`
	border: 1px solid #ddd;
	width: max-content;
	padding: 5px 10px;
	font-size: 20px;
	font-weight: 500;
	display: flex;
	gap: 3px;
`;

export const UserItem = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	svg {
		max-width: 20px;
		max-height: 20px;
	}
	button {
		max-height: 20px;
	}
	&.copy p {
		display: flex;
		align-items: center;
		gap: 7px;
		cursor: pointer;
		:hover {
			color: #f18320;
		}
		svg {
			width: 20px;
		}
	}
`;

export const ItemIcon = styled.img`
	width: 30px;
`;

export const Description = styled(P)`
	color: #333333;
`;

export const Divider = styled.div`
	width: 1px;
	background-color: #e6e6e6;
	height: 400px;
	align-self: center;
	@media only screen and (max-width: 960px) {
		transform: rotate(-180deg);
		width: 100%;
		height: 1px;
	}
`;
