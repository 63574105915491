import { Icons } from 'components/Icon/constants';
import Icon from 'components/Icon/Icon';
import { Subtitle } from 'styledComponents/Text';
import { Container, CardTitle } from './TestemonialCard.style';

interface TestemonialCardProps {
	iconName: Icons;
	title: string;
	subtitle: string;
}

const TestemonialCard: React.FC<TestemonialCardProps> = ({
	iconName,
	title,
	subtitle,
}) => (
	<Container className='card'>
		<Icon name={iconName} />
		<CardTitle>{title}</CardTitle>
		<Subtitle>{subtitle}</Subtitle>
	</Container>
);

export default TestemonialCard;
