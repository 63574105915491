export enum EventType {
	music = 'music',
	sport = 'sport',
	theatre = 'theatre',
	conference = 'conference',
	recreation = 'recreation',
}

export type TicketType = {
	eTicket: boolean;
	price: number;
	event: string;
	event_location: string;
	event_type: EventType;
	start: string;
	end?: string;
	description: string;
	id: number;
	createdAt: Date;
	owner_ID: number;
	picture?: string;
	owner: Owner;
};
export interface Owner {
	email: string;
	id: number;
	created_at: Date;
	location: string;
	first_name: string;
	last_name: string;
	phone_number: string;
}

export type CreateTicketRequestType = {
	e_ticket: boolean;
	price: number;
	event: string;
	event_location: string;
	event_type: string;
	start: string;
	end: string;
	description: string;
};

export const eventTypeLabels: { [key: string]: any } = {
	[EventType.music]: 'Muzika',
	[EventType.sport]: 'Sport',
	[EventType.theatre]: 'Pozorište',
	[EventType.conference]: 'Konferencija',
	[EventType.recreation]: 'Ostalo',
};
