import { FC, useMemo } from 'react';
import { Currency, P, ParagraphSize, Title } from 'styledComponents/Text';
import { useQuery } from 'react-query';
import { getTicket } from 'api/tickets';
import { useParams } from 'react-router-dom';
import { LoadingContainer } from 'components/Loader/Loader.style';
import Loader from 'components/Loader/Loader';
import { TicketType } from 'models/ticket';
import { format } from 'date-fns';
import { eventIcons } from 'components/Ticket/helpers';
import { Helmet } from 'react-helmet-async';
import Icon from 'components/Icon/Icon';
import { Icons } from 'components/Icon/constants';
import { toast } from 'react-toastify';
import {
	FacebookShareButton,
	WhatsappShareButton,
	FacebookIcon,
	WhatsappIcon,
	TwitterShareButton,
	TwitterIcon,
} from 'react-share';
import { srLatn } from 'date-fns/locale';
import {
	ContentContainer,
	Description,
	Divider,
	TicketContainer,
	Price,
	UserContainer,
	UserItem,
} from './Ticket.style';

export const Ticket: FC = () => {
	const { slug } = useParams();

	const id = useMemo(() => slug && slug?.split('-')?.slice(-1), [slug]);

	const { data, isLoading } = useQuery(
		['getTicket', id],
		() => getTicket(id as unknown as number),
		{
			enabled: !!id,
		}
	);

	const ticket: TicketType = useMemo(() => data, [data]);

	const metaData = {
		title: ticket?.event,
		description: `${ticket?.event} ${ticket?.event_location} ${ticket?.description}`,
		keywords: `Prodaja karti za ${ticket?.event?.split(' ')}${
			ticket?.event_location
		}`,
	};

	const ticketUrl = `https://kartaviska.rs/karte/${slug}`;

	const copyToClipboard = () => {
		navigator.clipboard.writeText(`https://kartaviska.rs/karte/${slug}`);
		toast.success(
			'Link do karte uspešno kopiran! Možete podeliti ovu kartu sa Vašim prijateljima.'
		);
	};

	const hastahgs = `#${ticket?.event} #kartaviska #prodajemkartu #preprodajakarte #kartaviska.com`;

	return (
		<ContentContainer>
			<Helmet>
				<meta charSet='utf-8' />
				<title>{metaData?.title}</title>
				<link rel='canonical' href={`https://kartaviska.rs/karte/${slug}`} />
				<meta name='description' content={metaData?.description} />
				<meta name='keywords' content={metaData?.keywords} />
				<meta property='og:title' content={metaData?.title} />
				<meta property='og:type' content='website' />
				<meta
					property='og:url'
					content={`https://kartaviska.rs/karte/${slug}`}
				/>
				<meta property='og:description' content={metaData?.description} />
				<meta
					property='og:image'
					content='http://kartaviska.rs/images/logo-social.png'
				/>
				<meta
					property='og:image:secure'
					content='https://kartaviska.rs/images/logo-social.png'
				/>
				<meta
					property='og:image:secure_url'
					content='https://kartaviska.rs/images/logo-social.png'
				/>
				<meta property='og:image:alt' content='kartaviska.rs' />
			</Helmet>
			{isLoading && (
				<LoadingContainer>
					<Loader />
				</LoadingContainer>
			)}
			{!isLoading && ticket && (
				<>
					<TicketContainer>
						<Title>{ticket.event || '-'}</Title>
						<img src={eventIcons[ticket.event_type]} alt={ticket?.event} />
						<UserItem>
							<P size={ParagraphSize.md} weight='bold'>
								Datum i vreme:
							</P>
							<P size={ParagraphSize.lg}>
								{format(new Date(ticket.start), 'dd. MMMM yyyy - HH:mm', {
									locale: srLatn,
								}) || '-'}
								h
							</P>
						</UserItem>
						<UserItem>
							<P size={ParagraphSize.md} weight='bold'>
								Lokacija:
							</P>
							<P size={ParagraphSize.lg}>{ticket.event_location} </P>
						</UserItem>
						<Description>{ticket.description || '-'}</Description>

						<Price>
							{ticket.price}
							<Currency>rsd</Currency>
						</Price>
						<UserItem>
							<P size={ParagraphSize.lg} weight='bold'>
								Podeli:{' '}
							</P>
						</UserItem>
						<UserItem className='copy'>
							<FacebookShareButton
								quote={ticket?.event}
								hashtag={hastahgs}
								url={ticketUrl}
							>
								<FacebookIcon />
							</FacebookShareButton>
							<WhatsappShareButton
								title={`${ticket.event} - ${ticket.price} rsd`}
								url={ticketUrl}
							>
								<WhatsappIcon />
							</WhatsappShareButton>
							<TwitterShareButton title={ticket.event} url={ticketUrl}>
								<TwitterIcon />
							</TwitterShareButton>
							<P
								size={ParagraphSize.md}
								weight='bold'
								onClick={copyToClipboard}
							>
								<Icon name={Icons.copy} /> Kopiraj link do karte
							</P>
						</UserItem>
					</TicketContainer>
					<Divider />
					<UserContainer>
						<Title>Kontaktiraj prodavca</Title>
						<UserItem>
							<P size={ParagraphSize.md} weight='bold'>
								Prodavac:
							</P>
							<P size={ParagraphSize.lg}>
								{`${ticket.owner?.first_name} ${ticket.owner?.last_name}` ||
									'-'}
							</P>
						</UserItem>
						<UserItem>
							<P size={ParagraphSize.md} weight='bold'>
								Email:
							</P>
							<P size={ParagraphSize.lg}>{ticket.owner?.email}</P>
						</UserItem>
						<UserItem>
							<P size={ParagraphSize.md} weight='bold'>
								Kontakt telefon:
							</P>
							<P size={ParagraphSize.lg}>{ticket.owner?.phone_number}</P>
						</UserItem>
						<UserItem>
							<P size={ParagraphSize.md} weight='bold'>
								Grad:
							</P>
							<P size={ParagraphSize.lg}>{ticket?.owner?.location}</P>
						</UserItem>
					</UserContainer>
				</>
			)}
		</ContentContainer>
	);
};
