import React from 'react';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import AuthProvider from 'context/AuthContext';
import { HelmetProvider } from 'react-helmet-async';
import { createRoot, hydrateRoot } from 'react-dom/client';
import FiltersProvider from 'context/FiltersContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Layout } from './Layout/Layout';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			retry: false,
		},
	},
});

if (container.hasChildNodes()) {
	hydrateRoot(
		container,
		<React.StrictMode>
			<GoogleOAuthProvider clientId='74196677993-pp0j89jhi31aavim0ee7kpaunoe2k07m.apps.googleusercontent.com'>
				<QueryClientProvider client={queryClient}>
					<AuthProvider>
						<FiltersProvider>
							<BrowserRouter>
								<HelmetProvider>
									<Layout>
										<App />
									</Layout>
								</HelmetProvider>
							</BrowserRouter>
						</FiltersProvider>
					</AuthProvider>
				</QueryClientProvider>
			</GoogleOAuthProvider>
		</React.StrictMode>
	);
} else {
	root.render(
		<React.StrictMode>
			<GoogleOAuthProvider clientId='74196677993-pp0j89jhi31aavim0ee7kpaunoe2k07m.apps.googleusercontent.com'>
				<QueryClientProvider client={queryClient}>
					<AuthProvider>
						<FiltersProvider>
							<BrowserRouter>
								<HelmetProvider>
									<Layout>
										<App />
									</Layout>
								</HelmetProvider>
							</BrowserRouter>
						</FiltersProvider>
					</AuthProvider>
				</QueryClientProvider>
			</GoogleOAuthProvider>
		</React.StrictMode>
	);
}

// root.render(
// 	<React.StrictMode>
// 		<QueryClientProvider client={queryClient}>
// 			<AuthProvider>
// 				<BrowserRouter>
// 					<HelmetProvider>
// 						<Layout>
// 							<App />
// 						</Layout>
// 					</HelmetProvider>
// 				</BrowserRouter>
// 			</AuthProvider>
// 		</QueryClientProvider>
// 	</React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
