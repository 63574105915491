import { getEventTypes, getTickets } from 'api/tickets';
import EventType from 'components/EventType/EventType';
import { Icons } from 'components/Icon/constants';
import Icon from 'components/Icon/Icon';
import Loader from 'components/Loader/Loader';
import { LoadingContainer } from 'components/Loader/Loader.style';
import { Ticket } from 'components/Ticket/Ticket';
import { EventType as EventTypeType, TicketType } from 'models/ticket';
import { useQuery } from 'react-query';
import { A, Title } from 'styledComponents/Text';
import { SEE_ALL_LABEL } from './constants';
import {
	CardsTitle,
	CategoryContainer,
	NoDataContainer,
	TicketsContainer,
} from './Home.style';

export const Home = () => {
	const { data, isLoading } = useQuery(['tickets'], () => getTickets(), {
		refetchOnMount: true,
	});

	const { data: eventTypes, isLoading: isEventTypesLoading } = useQuery(
		'event-types',
		getEventTypes
	);

	return (
		<>
			<Title>Istraži kategorije</Title>

			<CategoryContainer>
				{isEventTypesLoading && (
					<LoadingContainer>
						<Loader />
					</LoadingContainer>
				)}
				{!isEventTypesLoading && !eventTypes?.length && (
					<NoDataContainer>
						<Title color='#696767'>Trenutno nemamo karte u ponudi.</Title>
						<A to='/prodaj-kartu'>Oglasu kartu</A>
					</NoDataContainer>
				)}
				{!isEventTypesLoading &&
					!!eventTypes?.length &&
					eventTypes?.map((type: EventTypeType) => (
						<EventType key={type} eventType={type} />
					))}
			</CategoryContainer>
			<CardsTitle>
				<Icon name={Icons.ticket} />
				<Icon name={Icons.ticket} /> Sve karte
			</CardsTitle>

			<TicketsContainer>
				{isLoading && (
					<LoadingContainer>
						<Loader />
					</LoadingContainer>
				)}
				{!isLoading && !data?.length && (
					<NoDataContainer>
						<Title color='#696767'>Trenutno nemamo karte u ponudi.</Title>
						<A to='/prodaj-kartu'>Oglasi kartu</A>
					</NoDataContainer>
				)}
				{!isLoading && !!data?.length && (
					<>
						{data?.slice(0, 6).map((ticket: TicketType) => (
							<Ticket ticket={ticket} key={ticket.id} width='32%' />
						))}
						<A align='flex-end' to='/karte'>
							{SEE_ALL_LABEL}
						</A>
					</>
				)}
			</TicketsContainer>
		</>
	);
};
