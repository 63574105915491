// import { EventType, TicketType } from 'models/ticket';

// export const mockTickets: TicketType[] = [
// 	{
// 		id: 1,
// 		name: 'Gorillas Concert',
// 		price: 2000,
// 		dateTime: '20/12/2022',
// 		location: 'Beograd',
// 		eventType: EventType.music,
// 	},
// 	{
// 		id: 2,
// 		name: 'Partizan vs Crvena Zvezda',
// 		price: 700,
// 		dateTime: '08/08/2022',
// 		location: 'Beograd',
// 		eventType: EventType.sports,
// 	},
// 	{
// 		id: 3,
// 		name: 'Filip Krajinovic vs Miomir Kecmanovic',
// 		price: 2000,
// 		dateTime: '09/11/2022',
// 		location: 'Beograd',
// 		eventType: EventType.sports,
// 	},
// 	{
// 		id: 4,
// 		name: 'Filip Krajinovic vs Miomir Kecmanovic',
// 		price: 2000,
// 		dateTime: '09/11/2022',
// 		location: 'Beograd',
// 		eventType: EventType.other,
// 	},
// 	{
// 		id: 5,
// 		name: 'Filip Krajinovic vs Miomir Kecmanovic',
// 		price: 2000,
// 		dateTime: '09/11/2022',
// 		location: 'Beograd',
// 		eventType: EventType.sports,
// 	},
// 	{
// 		id: 6,
// 		name: 'Filip Krajinovic vs Miomir Kecmanovic',
// 		price: 2000,
// 		dateTime: '09/11/2022',
// 		location: 'Beograd',
// 		eventType: EventType.other,
// 	},
// 	{
// 		id: 7,
// 		name: 'Filip Krajinovic vs Miomir Kecmanovic',
// 		price: 2000,
// 		dateTime: '09/11/2022',
// 		location: 'Beograd',
// 		eventType: EventType.other,
// 	},
// 	{
// 		id: 8,
// 		name: 'Filip Krajinovic vs Miomir Kecmanovic',
// 		price: 2000,
// 		dateTime: '09/11/2022',
// 		location: 'Beograd',
// 		eventType: EventType.sports,
// 	},
// ];

export const SEE_ALL_LABEL = 'Pogledaj sve';
