import Modal from 'components/Modal/Modal';
import TestemonialCard from 'components/TestemonitalCard/TestemonialCard';
import { meta } from 'constants/seo';
import Login from 'pages/Login/Login';
import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { P, ParagraphSize, Title } from 'styledComponents/Text';
import { BannerBackgroundImage, testemonials } from './constants';
import { Header } from './Header/Header';
import {
	LayoutContainer,
	PageContainer,
	Wrapper,
	AdContainer,
	BigTitle,
	TestemonialContainer,
	Footer,
} from './Layout.style';

interface LayoutProps {
	children: JSX.Element;
}

export const Layout: FC<LayoutProps> = ({ children }): JSX.Element => {
	const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

	const { pathname } = useLocation();

	const bannerTitle = BannerBackgroundImage(pathname.split('/')[2])[pathname]
		?.title;
	const bannerBigTitle = BannerBackgroundImage(pathname.split('/')[2])[pathname]
		?.bigTitle;

	const closeModal = () => {
		setIsLoginModalOpen(false);
	};

	const openLoginModal = () => {
		setIsLoginModalOpen(true);
	};

	useEffect(() => {
		if (isLoginModalOpen) document.body.style.overflowY = 'hidden';
		else document.body.style.overflowY = 'auto';
	}, [isLoginModalOpen]);

	const metaPage = meta[pathname];

	return (
		<Wrapper>
			<Helmet prioritizeSeoTags>
				<meta charSet='utf-8' />
				<title>{metaPage?.title}</title>
				<link rel='canonical' href={`https://kartaviska.rs${pathname}`} />
				<meta name='description' content={metaPage?.description} />
				<meta name='keywords' content={metaPage?.keywords} />
				<meta property='og:title' content={metaPage?.title} />
				<meta property='og:type' content='website' />
				<meta property='og:url' content={`https://kartaviska.rs${pathname}`} />
				<meta
					property='og:image'
					content='http://kartaviska.rs/images/logo-social.png'
				/>
				<meta
					property='og:image:secure'
					content='https://kartaviska.rs/images/logo-social.png'
				/>
				<meta
					property='og:image:secure_url'
					content='https://kartaviska.rs/images/logo-social.png'
				/>
				<meta property='og:image:alt' content='kartaviska.rs' />
			</Helmet>
			{isLoginModalOpen && (
				<Modal
					title='Uloguj se'
					isModalOpen={isLoginModalOpen}
					closeModal={closeModal}
					btnLabel='Uloguj se'
					useCustomFooter
				>
					<Login onSuccess={closeModal} />
				</Modal>
			)}
			<LayoutContainer>
				<Header openLoginModal={openLoginModal} />
				<AdContainer page={pathname}>
					{bannerTitle && (
						<Title color='#fff' regular>
							{bannerTitle}
						</Title>
					)}
					{bannerBigTitle && <BigTitle>{bannerBigTitle}</BigTitle>}
				</AdContainer>
				{pathname === '/' && (
					<TestemonialContainer>
						{testemonials.map((testemonial) => (
							<TestemonialCard key={testemonial.title} {...testemonial} />
						))}
					</TestemonialContainer>
				)}
				<PageContainer fullWidth={pathname === '/karte'}>
					{children}
				</PageContainer>
				{pathname !== '/karte' && (
					<Footer>
						<P size={ParagraphSize.sm}>Kontakt i poslovna saradnja:</P>
						<a
							href='mailto:kontakt@kartaviska.rs'
							target='_blank'
							rel='noreferrer'
						>
							kontakt@kartaviska.rs
						</a>
					</Footer>
				)}
			</LayoutContainer>
		</Wrapper>
	);
};
