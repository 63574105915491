import { Route, Routes } from 'react-router-dom';
import { routes } from 'constants/routes';
import axios from 'axios';
import { BASE_API } from 'api/api.config';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

axios.defaults.baseURL = BASE_API;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['X-API-KEY'] = 'super_secret_api_key';

function App() {
	return (
		<div className='App'>
			<Routes>
				{routes.map((route) => (
					<Route {...route} key={route.path} />
				))}
			</Routes>
			<ToastContainer />
		</div>
	);
}

export default App;
