type MetaType = {
	[key: string]: {
		title: string;
		description: string;
		keywords: string;
	};
};
export const meta: MetaType = {
	'/': {
		title: 'Karta viška',
		description:
			'Sajt za kupovinu i prodaju karata. Prodajte kartu. Pronadjite kartu za koncerte, pozorista, utakmice, sportske dogadjaje, konferencije. Prodajte vasu kartu viska! Vasa karta viska!',
		keywords:
			'ulaznice, karte, koncerti, konferencije, utakmice, prodaja, karata, kupovina, karata, oglasi, ulaznicu, karta, viska',
	},
	'/karte': {
		title: 'Karte',
		description:
			'Razgledaj raspolozive karte, prodaj svoju kartu. Istrazi karte za razlicite aktuelne dogadjaje!',
		keywords:
			'utakmice, ulaznice, karte, koncerti, pozoriste, konferencije, istrazi, karte, dogadjaji, pronadji',
	},
	'/prodaj-kartu': {
		title: 'Prodaj kartu',
		description:
			'Imas kartu viska? Ne mozes da stignes na neki dogadjaj? Prodaj svoju kartu i zaradi novac!',
		keywords:
			'prodaj, kartu, viska, zaradi, novac, oglasi, ulaznicu, za, koncert, utakmicu',
	},
	'/registracija': {
		title: 'Napravi nalog',
		description:
			'Napravi nalog i oglasi svoje karte! Prati koje sve karte si prodao, zaradi novac!',
		keywords:
			'napravi, nalog, registruj, se, prodaj, kartu, evidencija, o, oglasenim, kartama',
	},
};
