import { eventIcons } from 'components/Ticket/helpers';
import { FiltersContext } from 'context/FiltersContext';
import { EventType as EventTypeType, eventTypeLabels } from 'models/ticket';
import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Title } from 'styledComponents/Text';
import { EventPoster, EventTypeContainer } from './EventType.style';

interface EventTypeProps {
	eventType: EventTypeType;
}

const EventType: FC<EventTypeProps> = ({ eventType }) => {
	const navigate = useNavigate();
	const { setEventTypes } = useContext(FiltersContext);

	const handleDisplayCategory: React.MouseEventHandler<HTMLAnchorElement> = (
		e
	) => {
		e.preventDefault();
		setEventTypes([eventType]);
		navigate('/karte');
	};

	return (
		<EventTypeContainer to='/karte' onClick={handleDisplayCategory}>
			<EventPoster src={eventIcons[eventType]} />
			<Title>{eventTypeLabels[eventType]}</Title>
		</EventTypeContainer>
	);
};

export default EventType;
