import axios from 'axios';
import { CreateUserRequestType, EditUserType } from 'models/user';

const ENDPOINT = 'login';
const CREATE_ENDPOINT = 'users';

export const login = async ({
	email,
	password,
}: Partial<CreateUserRequestType>) => {
	const creds = { username: email, password };

	const { data } = await axios.post(`${ENDPOINT}`, creds, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	});

	return data;
};

export const register = async ({
	email,
	password,
	first_name,
	last_name,
	phone_number,
	location,
}: CreateUserRequestType) => {
	const userData = {
		email,
		password,
		first_name,
		last_name,
		phone_number,
		location,
	};

	const { data } = await axios.post(`${CREATE_ENDPOINT}/`, userData);

	return data;
};

export const googleSignUp = async (idToken: string) => {
	const { data } = await axios.post(`/google/authentication`, null, {
		params: {
			google_token: idToken,
		},
		headers: {
			'Content-Type': 'application/json',
		},
	});

	return data;
};

/// POST
// /users/account/update/

export const edit = async ({
	id,
	phone_number,
	location,
	fb_profile,
}: EditUserType) => {
	const userData = {
		id,
		phone_number,
		location,
		fb_profile,
	};

	const { data } = await axios.post(`${CREATE_ENDPOINT}/`, userData, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem('jwt')}`,
		},
	});

	return data;
};
