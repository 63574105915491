import { eventTypeLabels, TicketType } from 'models/ticket';
import { FC, useMemo } from 'react';
import { Currency, ParagraphSize, Title } from 'styledComponents/Text';
import { format } from 'date-fns';
import Icon from 'components/Icon/Icon';
import { Icons } from 'components/Icon/constants';
import { srLatn } from 'date-fns/locale';
import { slugify } from 'helpers/stringHelpers';
import { eventIcons, getDayMonthYear } from './helpers';
import {
	TicketPoster,
	Day,
	EventName,
	MonthYear,
	TicketContainer,
	TicketInfo,
	EventLocation,
	Price,
	TicketIcon,
	InfoCol,
	EventCategory,
	TicketDate,
	PriceLocationContainer,
	EventTime,
	Time,
	VerticalContainer,
	VerticalInfo,
	InfoSection,
	Label,
	DashedContainer,
	VerticalPrice,
} from './Ticket.style';

interface TicketProps {
	ticket?: TicketType;
	width?: string;
	vertical?: boolean;
}

export const Ticket: FC<TicketProps> = ({
	ticket,
	width = '48%',
	vertical = false,
}): JSX.Element => {
	// Todo: format date from API
	const eventDate =
		ticket?.start &&
		format(new Date(ticket.start), 'dd MMM yyyy', {
			locale: srLatn,
		});

	const ticketDate = useMemo(
		() => getDayMonthYear(eventDate as string),
		[eventDate]
	);

	const ticketTime = useMemo(
		() =>
			ticket?.start &&
			format(new Date(ticket?.start), 'HH:mm', { locale: srLatn }),
		[ticket?.start]
	);

	if (!vertical) {
		return (
			<TicketContainer width={width}>
				<TicketPoster to={`/karte/${slugify(ticket?.event)}-${ticket?.id}`}>
					{ticket?.event_type && (
						<TicketIcon
							src={eventIcons[ticket.event_type]}
							alt={ticket?.event}
						/>
					)}
					<TicketDate>
						<Day>{ticketDate.day || '-'}</Day>
						<MonthYear size={ParagraphSize.md}>
							{ticketDate?.monthYear}
						</MonthYear>
					</TicketDate>
					<PriceLocationContainer>
						<Price size={ParagraphSize.md} color='#fff'>
							{ticket?.price} <Currency>RSD</Currency>
						</Price>
						<EventLocation size={ParagraphSize.sm}>
							<Icon name={Icons.location} /> {ticket?.event_location}
						</EventLocation>
					</PriceLocationContainer>
				</TicketPoster>
				<TicketInfo>
					<InfoCol>
						<EventName
							to={`/karte/${slugify(ticket?.event)}-${ticket?.id}`}
							className='event-name'
						>
							{ticket?.event}
						</EventName>
						<EventCategory>
							{eventTypeLabels[ticket?.event_type as string]}
						</EventCategory>
					</InfoCol>
					<InfoCol>
						<EventTime>
							<Time>{eventDate}</Time>
							<Time>Početak u {ticketTime}</Time>
						</EventTime>
					</InfoCol>
				</TicketInfo>
			</TicketContainer>
		);
	} else {
		return (
			<VerticalContainer>
				<TicketPoster
					to={`/karte/${slugify(ticket?.event)}-${ticket?.id}`}
					className='ticket-poster'
				>
					{ticket?.event_type && (
						<TicketIcon
							src={eventIcons[ticket.event_type]}
							alt={ticket?.event}
						/>
					)}
					<TicketDate>
						<Day>{ticketDate.day || '-'}</Day>
						<MonthYear size={ParagraphSize.md}>
							{ticketDate?.monthYear}
						</MonthYear>
					</TicketDate>
				</TicketPoster>
				<DashedContainer>
					<VerticalInfo>
						<InfoSection>
							<EventName
								to={`/karte/${slugify(ticket?.event)}-${ticket?.id}`}
								className='event-name'
							>
								{ticket?.event}
							</EventName>
							<EventCategory className='event-category'>
								{eventTypeLabels[ticket?.event_type as string]}
							</EventCategory>
						</InfoSection>
						<InfoSection>
							<Label>
								<Icon name={Icons.locationOrange} /> {ticket?.event_location}
							</Label>
							<Label>
								<Icon name={Icons.clock} />
								{eventDate}, Početak u {ticketTime}
							</Label>
						</InfoSection>
					</VerticalInfo>
					<VerticalPrice>
						<Title>
							{ticket?.price} <Currency>rsd</Currency>{' '}
						</Title>
					</VerticalPrice>
				</DashedContainer>
			</VerticalContainer>
		);
	}
};
