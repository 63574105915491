import { FieldErrorsImpl } from 'react-hook-form';
import { ErrorMsg } from 'styledComponents/form';

export const getValidationError = (fieldName: string) =>
	`* ${fieldName} je obavezno polje`;

export const displayValidationError = (
	fieldName: string,
	errors: Partial<
		FieldErrorsImpl<{
			[x: string]: any;
		}>
	>
) => {
	if (errors && errors[fieldName]) {
		return <ErrorMsg>{errors[fieldName]?.message as string}</ErrorMsg>;
	}
	return '';
};
