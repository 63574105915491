import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonOutline } from 'styledComponents/buttons';

export interface LinkProps {
	isActive?: boolean;
}

export const HeaderContainer = styled.div`
	position: sticky;
	top: 0;
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	gap: 100px;
	padding: 13px 32px;
	background-color: rgba(30, 30, 30, 0.6);
	z-index: 1;
	&.active {
		transition: 0.2s;
		background: rgba(30, 30, 30, 1);
	}
	@media only screen and (max-width: 960px) {
		align-items: center;
	}
`;

export const Logo = styled(Link)`
	margin-bottom: 0;
	font-weight: 700;
	font-size: 20px;
	line-height: 30px;
	text-decoration: none;
	color: #ffffff;
	span {
		color: #f18320;
	}
`;

export const Navigation = styled.div`
	display: flex;
	align-items: baseline;
	gap: 20px;
	.logout {
		background-color: transparent;
		padding: 0;
		color: #ddd;
	}
	@media only screen and (max-width: 960px) {
		align-items: center;
		.login,
		.logout {
			display: none;
		}
	}
`;

export const StyledLink = styled(Link)<LinkProps>`
	text-decoration: none;
	border-bottom: ${(props) =>
		props.isActive ? '2px solid #f18320' : '2px solid transparent'};
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	text-transform: uppercase;
	color: #ffffff;
	&:not(:last-child):hover {
		transition: 0.2s;
		border-bottom: 2px solid #f18320;
	}
	@media only screen and (max-width: 960px) {
		display: none;
	}
`;

export const Additional = styled.div`
	display: flex;
	margin-left: auto;
	a {
		background-color: #6246ea;
		font-size: 16px;
		padding: 10px;
		border-radius: 4px;
		color: #ffe;
		display: flex;
		align-items: center;
		gap: 6px;

		:hover {
			transition: 0.1s;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
			color: #ffe;
		}
	}
`;

export const Hamburger = styled(ButtonOutline)`
	display: none;
	flex-direction: column;
	padding: 11px 20px;
	gap: 5px;
	div {
		height: 2px;
		background-color: #fff;
		width: 20px;
	}
	:hover {
		div {
			background-color: #f18320;
		}
	}
	@media only screen and (max-width: 960px) {
		display: flex;
	}
`;

export const CloseIcon = styled.div`
	display: none;
	cursor: pointer;
	svg {
		color: #fff;
		width: 30px;
		height: 42px;
	}

	:hover {
		svg {
			color: #f18320;
		}
	}

	@media only screen and (max-width: 960px) {
		display: flex;
	}
`;

interface MobileNavigationProps {
	isActive: boolean;
}

export const MobileNavigation = styled.div<MobileNavigationProps>`
	animation: fade-in 0.3s;
	position: absolute;
	left: 0;
	top: -2px;
	width: 100%;
	height: 100vh;
	display: ${(props) => (props.isActive ? 'flex' : 'none')};
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: rgba(30, 30, 30, 0.6);
	gap: 50px;
	z-index: -1;

	a {
		display: block;
		width: max-content;
		font-size: 28px;
	}

	@keyframes fade-in {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

export const ProfileLink = styled(StyledLink)`
	color: #f18320;
`;
