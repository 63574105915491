import axios from 'axios';
import { CreateTicketRequestType } from 'models/ticket';
import qs from 'qs';

const ENDPOINT = 'tickets';

export const getTickets = async (
	event_types?: string[],
	event?: string,
	max_price?: string,
	min_price?: string
) => {
	const { data } = await axios.get(`${ENDPOINT}`, {
		params: {
			event_types,
			max_price: max_price || 10000,
			min_price: min_price || 0,
			event,
		},
		paramsSerializer: {
			serialize: (params) =>
				qs.stringify(params, { arrayFormat: 'repeat', encode: false }),
		},
	});

	return data;
};

export const getTicket = async (id?: number) => {
	const { data } = await axios.get(`${ENDPOINT}/${id}`);

	return data;
};

// TODO: Create request
export const createTicket = async (ticket: CreateTicketRequestType) => {
	const { data } = await axios.post(`${ENDPOINT}`, ticket, {
		headers: {
			Authorization: `Bearer ${localStorage.getItem('jwt')}`,
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	});

	return data;
};

export const getEventTypes = async () => {
	const { data } = await axios.get(`${ENDPOINT}/event_types`);
	return data;
};

export const getMyTickets = async () => {
	const { data } = await axios.get(`${ENDPOINT}/my_tickets/`, {
		headers: {
			Authorization: `Bearer ${localStorage.getItem('jwt')}`,
		},
	});

	return data;
};

export const deleteTicket = async (id: number) => {
	const { data } = await axios.delete(`${ENDPOINT}/${id}`, {
		headers: {
			Authorization: `Bearer ${localStorage.getItem('jwt')}`,
		},
	});
	return data;
};

export const editTicket = async ({
	id,
	ticket,
}: {
	id: number;
	ticket: CreateTicketRequestType;
}) => {
	const { data } = await axios.put(`${ENDPOINT}/${id}`, ticket, {
		headers: {
			Authorization: `Bearer ${localStorage.getItem('jwt')}`,
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	});
	return data;
};
